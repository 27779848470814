import "../App.css"
import React, { Component, Fragment } from "react"
import styled from "../components/styled"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Image from "../components/frontpage/image"
import IntroText from "../components/frontpage/introtext"
import { Link } from "gatsby"

const SectionWrapper = styled("div")`
  position: relative;
  width: 100%;
  display: flex;

  margin-bottom: 50vw;

  @media screen and (min-width: 1025px) {
    margin-bottom: 25vw;
  }
`

const Wrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const GenericWrapper = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`
const StyledH = styled("h2")`
  color: black;
  font-size: 4.5vw;
  font-family: "Helvetica Neue", sans-serif;
  margin: 0;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: ${props => (props.mt ? props.mt : null)};
  margin-left: 5vw;
  pointer-events: none;
  z-index: 1;
  transform: rotate(25deg);
  transform-origin: 0 0;
  text-decoration: underline;
  @media screen and (max-width: 1025px) {
    font-size: 62px;
    line-height: 1.2;
  }
  @media screen and (max-width: 769px) {
    font-size: 56px;
    line-height: 1.2;
  }
  @media screen and (max-width: 577px) {
    font-size: 36px;
    line-height: 1.2;
  }
  @media screen and (min-width: 1025px) {
    margin-top: 10vh;
  }
`

class Frontpage extends Component {
  componentDidMount() {
    document && document.body.style.setProperty("background-color", "white")
  }

  render() {
    const { data } = this.props

    const projects = data.allMarkdownRemark.edges

    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>Joel Sundqvist</title>
          <meta
            name="description"
            content="joel sundqvist · slow fashion · textiles · designer · developer · portfolio · contact · work · instagram
            "
          ></meta>
        </Helmet>

        <SectionWrapper className="content__section">
          <IntroText></IntroText>
          <StyledH as="h1" mt="10vw">
            Joel Sundqvist
          </StyledH>
        </SectionWrapper>

        {projects &&
          projects.map((item, index) => (
            <Fragment key={index}>
              <SectionWrapper className="content__section">
                <Wrapper>
                  <GenericWrapper to={"/project/"} state={{ item }}>
                    <Image
                      desktopImage={item.node.frontImageDesktop.fluid}
                      mobileImage={item.node.frontImageMobile.fluid}
                    ></Image>
                  </GenericWrapper>

                  <StyledH>{item.node.frontmatter.title}</StyledH>
                </Wrapper>
              </SectionWrapper>
            </Fragment>
          ))}
      </div>
    )
  }
}

export default Frontpage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { title: { ne: "Portfolio" } } }
    ) {
      edges {
        node {
          excerpt
          frontImageDesktop {
            fluid {
              ...CloudinaryAssetFluid
            }
          }
          frontImageMobile {
            fluid {
              ...CloudinaryAssetFluid
            }
          }
          heroImageDesktop {
            fluid {
              ...CloudinaryAssetFluid
            }
          }

          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            fullPage
            threeImages
            bg_color
            text_color
            description1
            description2
            description3
            imageListMobile {
              image
              title
            }
            imageListDesktop {
              image
              title
            }
          }
        }
      }
    }
  }
`
