import React, { Fragment } from "react"
import { Above, Below } from "../../Breakpoints"
import NewImage from "../newImage"

const Image = ({ desktopImage, mobileImage }) => (
  <Fragment>
    <Above
      height="100%"
      width="32%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      breakpoint="lg"
    >
      <NewImage
        wrapperStyle={{
          userSelect: "none",
          pointerEvents: "none",
          maxHeight: "100%",
          width: "100%",
        }}
        imgStyle={{ objectFit: "contain", objectPosition: "center right" }}
        src={desktopImage}
      />
    </Above>
    <Below
      height="100%"
      width="78%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      breakpoint="lg"
    >
      <NewImage
        wrapperStyle={{
          userSelect: "none",
          pointerEvents: "none",
          maxHeight: "100%",
          width: "100%",
        }}
        imgStyle={{ objectFit: "contain", objectPosition: "center right" }}
        src={mobileImage}
      />
    </Below>
  </Fragment>
)

export default Image
