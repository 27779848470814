import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled("div")`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 1;
  @media screen and (max-width: 1025px) {
    margin-top: 70vw;
  }
`

const BigText = styled("p")`
  font-family: "Helvetica Neue", sans-serif;
  font-size: ${props => (props.fontSize ? props.fontSize : "4.5vw")};
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  margin-top: ${props => (props.mt ? props.mt : null)};
  line-height: 1;
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  color: ${props => (props.color ? props.color : "black")};

  @media screen and (max-width: 1025px) {
    font-size: 56px;
    line-height: 1.2;
  }
  @media screen and (max-width: 769px) {
    font-size: 48px;
    line-height: 1.2;
  }
  @media screen and (max-width: 577px) {
    font-size: 28px;
    line-height: 1.2;
  }
`

const ALink = styled("a")`
  text-align: right;
  cursor: pointer;
  position: relative;
  width: fit-content;
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1;
  margin-right: ${props => props.mr};
  font-size: 4.5vw;

  color: ${props => (props.color ? props.color : "black")};
  -webkit-text-stroke: ${props =>
    props.color === "white" ? "1px black" : null};

  @media screen and (max-width: 1025px) {
    -webkit-text-stroke: ${props =>
      props.color === "white" ? "0.8px black" : null};
    font-size: 56px;
    line-height: 1.2;
  }
  @media screen and (max-width: 769px) {
    -webkit-text-stroke: ${props =>
      props.color === "white" ? "0.6px black" : null};
    font-size: 48px;
    line-height: 1.2;
  }
  @media screen and (max-width: 577px) {
    -webkit-text-stroke: ${props =>
      props.color === "white" ? "0.4px black" : null};
    font-size: 28px;
    line-height: 1.2;
  }
`

const Email = styled("p")`
  text-align: right;
  user-select: text;
  position: relative;
  width: fit-content;
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1;
  font-size: 4.5vw;
  color: white;
  -webkit-text-stroke: 1px black;

  @media screen and (max-width: 1025px) {
    -webkit-text-stroke: 0.8px black;
    font-size: 56px;
    line-height: 1.2;
  }
  @media screen and (max-width: 769px) {
    -webkit-text-stroke: 0.6px black;
    font-size: 48px;
    line-height: 1.2;
  }
  @media screen and (max-width: 577px) {
    -webkit-text-stroke: 0.4px black;
    font-size: 28px;
    line-height: 1.2;
  }
`

const TextWrapper2 = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: ${props => props.align};
`

const ColumnWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`

const IntroText = () => (
  <Wrapper id="introtext">
    <ColumnWrapper>
      <BigText>textiles</BigText>
      <BigText>Slow fashion</BigText>
      <BigText>Designer / developer</BigText>
      <BigText>Rejecting consumerism</BigText>
      <Email>hello@joelsundqvist.com</Email>
      <BigText>Royal Danish Academy</BigText>
      <TextWrapper2>
        <ALink
          mr={"2vw"}
          target="_blank"
          href="https://instagram.com/_joelsundqvist_/"
        >
          instagram
        </ALink>
        {/*  <ALink
          target="_blank"
          href="https://www.dropbox.com/s/ownej4ww8qcako2/Portfolio202203.pdf?dl=0"
        >
          portfolio
        </ALink> */}
        <BigText>portfolio</BigText>
      </TextWrapper2>
    </ColumnWrapper>
  </Wrapper>
)

export default IntroText
